import React from "react";
const Widget = ({ titulo, descripcion, derecho = null }) => {
  return (
    <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="text-xs uppercase font-light text-grey-500">
            {titulo}
          </div>
          <div className="text-xl font-bold">{descripcion}</div>
        </div>
        {derecho}
      </div>
    </div>
  );
};

export default Widget;
