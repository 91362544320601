//crear funciones de manejar toda la información del token
import { ACCESS_TOKEN } from "../Constants/Contantes";
import jwtDecode from "jwt-decode";
//pasamos el token y guardamos en local storage
export function setToken(token) {
  localStorage.setItem(ACCESS_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function decodeToken(token) {
  return jwtDecode(token);
}

export function removeToken() {
  return localStorage.removeItem(ACCESS_TOKEN);
}
