import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import DireccionContext from "../../context/Direccion/direccionContext";
import { Dropdown } from "semantic-ui-react";
import { Standar } from "../Tools/Standar/Standar";
const Direccion = ({
  cid,
  setCid,
  userid,
  token,
  actualizar,
  setActualizar,
  nid,
  setNid,
  tipo,
  setTipo,
}) => {
  const DireContext = useContext(DireccionContext);

  const {
    direcciones,
    localidad,
    obtenerEstados,
    obtenerLocalidades,
    editaDireccion,
    estados,
    obtenerMunicipios,
    municipios,
    crearDireccion,
    mensaje,
  } = DireContext;
  const [direction, setDireccion] = useState({
    idcalle: "",
    noexterior: "",
    nointerior: "",
    lote: "",
    manzana: "",
    seccion: "",
    cruzamiento1: "",
    cruzamiento2: "",
    codigoPostal: "",
    ContribuyenteId: "",
    idcolonia: "",
    NegocioId: "",
    idlocalidad: "",
    idmunicipio: "",
    idestado: "",
  });

  console.log(mensaje);
  //Efecto para obtener las localidades
  useEffect(() => {
    obtenerLocalidades();
    obtenerEstados();
    obtenerMunicipios();
  }, []);

  const handleChange = (e, result) => {
    const { name, value } = result || e.target;
    setDireccion({
      ...direction,
      [name]: value,
    });
  };

  useEffect(() => {
    direcciones.length === 0
      ? setDireccion(direction)
      : setDireccion(direcciones);
  }, [direcciones]);

  useEffect(() => {
    if (!tipo) {
      if (cid === null || cid === undefined) {
      } else {
        direction.ContribuyenteId = cid;
        direction.NegocioId = nid;
        crearDireccion(userid, token, direction);
        setCid(null);
      }
    } else {
      if (nid === null || nid === undefined) {
      } else {
        direction.NegocioId = nid;
        direction.ContribuyenteId = cid;
        crearDireccion(userid, token, direction);
        setNid(null);
      }
    }
  }, [tipo, cid, nid]);

  //Actualizar

  useEffect(() => {
    console.log(mensaje);
    if (actualizar) {
      editaDireccion(
        userid,
        token,
        direction.id,
        direction.ContribuyenteId,
        direction.NegocioId,
        direction
      );
      Standar(
        "Información",
        "success",
        "Información actualizada correctamente"
      );
      setActualizar(false);
    }
  }, [actualizar, mensaje]);

  return (
    <>
    
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Calle</Form.Label>
            <Form.Control
              placeholder="Calle"
              name="idcalle"
              value={direction.idcalle}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>No. Exterior</Form.Label>
            <Form.Control
              placeholder="No. Exterior"
              name="noexterior"
              value={direction.noexterior}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>No. Interior</Form.Label>
            <Form.Control
              placeholder="No. Interior"
              name="nointerior"
              value={direction.nointerior}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Lote</Form.Label>
            <Form.Control
              placeholder="Lote"
              name="lote"
              value={direction.lote}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Manzana</Form.Label>
            <Form.Control
              placeholder="Manzana"
              name="manzana"
              value={direction.manzana}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Sección</Form.Label>
            <Form.Control
              placeholder="Sección"
              name="seccion"
              value={direction.seccion}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Primer Cruzamiento</Form.Label>
            <Form.Control
              placeholder="Primer Cruzamiento"
              name="cruzamiento1"
              value={direction.cruzamiento1}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Segundo Cruzamiento</Form.Label>
            <Form.Control
              placeholder="Segundo Cruzamiento"
              name="cruzamiento2"
              value={direction.cruzamiento2}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Código Postal</Form.Label>
            <Form.Control
              placeholder="Código Postal"
              name="codigoPostal"
              value={direction.codigoPostal}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Colonia</Form.Label>
            <Form.Control
              placeholder="Colonia"
              name="idcolonia"
              value={direction.idcolonia}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Localidad</Form.Label>
            <Dropdown
              placeholder="Selecciona una localidad"
              additionPosition="top"
              fluid
              search
              selection
              id="idlocalidad"
              options={localidad.map((l) => ({
                key: l.idlocalidad,
                value: l.idlocalidad,
                text: l.localidad,
              }))}
              value={direction.idlocalidad}
              onChange={(e, data) => {
                setDireccion({
                  ...direction,
                  idlocalidad: data.value,
                });
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Municipio</Form.Label>
            <Dropdown
              placeholder="Selecciona un estado"
              fluid
              search
              selection
              id="idmunicipio"
              options={municipios.map((l) => ({
                key: l.idmunicipio,
                value: l.idmunicipio,
                text: l.municipio,
              }))}
              value={direction.idmunicipio}
              onChange={(e, data) => {
                setDireccion({
                  ...direction,
                  idmunicipio: data.value,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Estados</Form.Label>

            <Dropdown
              placeholder="Selecciona un estado"
              fluid
              search
              selection
              id="idestado"
              options={estados.map((l) => ({
                key: l.c_estado,
                value: l.c_estado,
                text: l.nombre,
              }))}
              value={direction.idestado}
              onChange={(e, data) => {
                setDireccion({
                  ...direction,
                  idestado: data.value,
                });
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default Direccion;
