import React from "react";
import "../../css/layouts/layout-1.css";
const Centro = ({ children }) => {
  return (
    <div
      data-layout="centered"
      className="w-full h-screen flex items-center justify-center bg-grey-50"
    >
      {children}
    </div>
  );
};

export default Centro;
