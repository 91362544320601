import Swal from "sweetalert2";

//Alerta de contenido
export const Standar = (titulo, icon, msg) => {
  Swal.fire({
    title: titulo,
    text: msg,
    icon: icon,
    confirmButtonText: "Aceptar",
  });
};
