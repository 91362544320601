import { gql } from "@apollo/client";

//Obtener los contribuyente // 
export const OBTENER_CONTRIBUYENTES = gql`
  query ObtenerContribuyentes {
    obtenerContribuyentes {
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      curp
      rfc
      telefono
      celular
      email
      razonSocial
      tipopersona
    }
  }
`;
