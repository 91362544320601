import React from 'react';
import {Alert} from 'react-bootstrap'
const Danger = ({variante, mensaje}) => {
    return ( 
        <Alert variant={variante}>
            {mensaje}
        </Alert>
     );
}
 
export default Danger;