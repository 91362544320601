import React, { useContext, useEffect, useState } from "react";
import Title from "../components/Escritorio/TItle";
import Widget from "../components/Escritorio/Widget";
import AuthContext from "../context/Auth/authContext";
import { FiUser, FiDatabase, FiFile } from "react-icons/fi";
import ReporteadorContext from "../context/Reporteador/ReporteadorContext";
import Bar from "../components/Barras/Bar";
import Barras from "../components/Barras/Bar";
const Test = () => {
  const authContext = useContext(AuthContext);
  const reporteContext = useContext(ReporteadorContext);

  const { UsuarioAutenticado, usuario, token } = authContext;
  const {
    getMothLicences,
    getYearLicences,
    getTotalFactory,
    getTotalPeople,
    getGrafico1,
    ttfactory,
    yearLicences,
    mothLicences,
    ttpeople,
    grafico1,
  } = reporteContext;

  useEffect(() => {
    UsuarioAutenticado();
  }, []);

  useEffect(() => {
    (async () => {
      getMothLicences(usuario.id, token);
      getYearLicences(usuario.id, token);
      getTotalFactory(usuario.id, token);
      getTotalPeople(usuario.id, token);
      getGrafico1(usuario.id, token)
    })();
  }, []);

  return (
    <>
      <Title titulo="Inicio" subtitulo="Resumen" />
     

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
      <div className="w-full lg:w-1/0">
        <Barras grafico1={grafico1}/>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        {/* Widget Total de Contribuyentes */}
        <div className="w-full lg:w-1/4">
          <Widget
            titulo="Contribuyentes activos"
            descripcion={ttpeople.total}
            derecho={
              <FiUser size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
        {/* Negocios activos */}
        <div className="w-full lg:w-1/4">
          <Widget
            titulo="Negocios activos"
            descripcion={ttfactory.total}
            derecho={
              <FiDatabase size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
        {/* Licencias expedidas en el año */}
        <div className="w-full lg:w-1/4">
          <Widget
            titulo="Tramites realizados este año"
            descripcion={yearLicences.total}
            derecho={
              <FiFile size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
        {/* Trámites atendidos en el presente mes */}
        <div className="w-full lg:w-1/4">
          <Widget
            titulo="Tramites atendidos este mes"
            descripcion={mothLicences.total}
            derecho={
              <FiFile size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
      </div>

     
    </>
  );
};

export default Test;
