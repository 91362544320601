import {
  GET_GRAFICO1,
  GET_MOTH_LICENCES,
  GET_TOTAL_FACTORY,
  GET_TOTAL_PEOPLE,
  GET_YEAR_LICENCES,
} from "../../Types";
export default (state, action) => {
  switch (action.type) {
    case GET_MOTH_LICENCES:
      return {
        ...state,
        mothLicences: action.payload,
      };
    case GET_YEAR_LICENCES:
      return {
        ...state,
        yearLicences: action.payload,
      };
    case GET_TOTAL_FACTORY:
      return {
        ...state,
        ttfactory: action.payload,
      };
    case GET_TOTAL_PEOPLE:
      return {
        ...state,
        ttpeople: action.payload,
      };
    case GET_GRAFICO1: 
      return{
        ...state, 
        grafico1: action.payload
      }

    default:
      return state;
  }
};
