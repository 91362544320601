import React, { useEffect, useRef, useContext, useState } from "react";
import localApi from "../../../config/Config";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../../poral";
import { FiUser, FiX } from "react-icons/fi";
import Danger from "../../Alertas/Danger";
import printJs from "print-js";
import { Standar } from "../../Tools/Standar/Standar";
import {
  Dropdown,
  Input,
  Form as Formu,
  Select,
  Button,
} from "semantic-ui-react";
import { Col, Form } from "react-bootstrap";
import ContribuyenteContext from "../../../context/Contribuyente/contribuyenteContext";
import AuthContext from "../../../context/Auth/authContext";
import NegociosContext from "../../../context/Negocios/negociosContext";
import LicenciasContext from "../../../context/Licencias/LicenciasContext";
//Validaciones
import formvalidacion from "../../../validator/licencia";
import useValidacion from "../../../Hooks/UseValidation";
const STATE_INICIAL = {
  tipopersona: "",
  ContribuyenteId: "",
  NegocioId: "",
  actividad: "",
  horario: "",
  descripcion: "",
  tipolicencia: "",
};

const AddLicencia = ({ title, open, setOpen }) => {
  //Contextos
  const contribuyentCtx = useContext(ContribuyenteContext);
  const authCtx = useContext(AuthContext);
  const negocioCtxt = useContext(NegociosContext);
  const licenciaCtx = useContext(LicenciasContext);

  const { obtenerContribuyentes, contribuyentes } = contribuyentCtx;
  const { token, usuario } = authCtx;
  const { negocios, negocioContribuyente } = negocioCtxt;
  const {
    crearLicencia,
    informacion,
    imprimirLicencia,
    obtenerLicencias,
    urlprint,
  } = licenciaCtx;

  //Validaciones
  const {
    valores,
    errores,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useValidacion(STATE_INICIAL, formvalidacion, frmGuardar);

  //effects
  useEffect(() => {
    obtenerContribuyentes(usuario.id, token);
  }, []);

  //Setea el tipo de licencia
  //Obtener folio
  //Setear rol de usuario activo
  const [rol, setRol] = useState();

  //Construir el componente modal
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );

  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      console.log(modalRef.current.contains(event.target));
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  //Fin componente modal
  function frmGuardar() {
    crearLicencia(usuario.id, token, valores);
    obtenerLicencias(usuario.id, token);
  }

  useEffect(() => {
    switch (informacion.code) {
      case 200:
        setOpen(false);
        // valores = STATE_INICIAL
        Standar("Información", "success", informacion.message);
        imprimirLicencia(usuario.id, token, informacion.idlicencia);
        break;
      case 500:
        setOpen(false);
        Standar("Error", "error", informacion.message);

        break;
      default:
        break;
    }
  }, [informacion]);

  useEffect(() => {
    if (urlprint === "") {
    } else {
      printJs({
        printable: process.env.REACT_APP_PDF + urlprint,
        showModal: true,
      });
    }
  }, [urlprint]);

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <Formulario
                    contribuyentes={contribuyentes}
                    negocios={negocios}
                    negocioContribuyente={negocioContribuyente}
                    token={token}
                    usuario={usuario}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    valores={valores}
                  />
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

function Formulario(props) {
  const {
    contribuyentes,
    negocios,
    negocioContribuyente,
    usuario,
    token,
    handleSubmit,
    handleBlur,
    handleChange,
    valores,
  } = props;

  const { actividad, descripcion, horario, tipopersona } = valores;

  //States iniciales
  const [contId, setContribuyenteId] = useState({
    ContribuyenteId: "",
  });
  const [negoId, setNegocioId] = useState({
    NegocioId: "",
  });

  //State para setear el tipo de licencia según rol de usuario.
  const [tipLicencia, setTipoLicencia] = useState("0");
  const [sololectura, setSoloLectura] = useState(true);

  useEffect(() => {
    valores.ContribuyenteId = contId.ContribuyenteId;
    negocioContribuyente(usuario.id, token, contId.ContribuyenteId);
  }, [contId]);

  useEffect(() => {
    if (negocios.length === 0) {
      valores.NegocioId = "";
      setNegocioId("");
    }
    valores.NegocioId = negoId.NegocioId;
  }, [negoId, negocios]);

  useEffect(() => {
    valores.tipolicencia = tipLicencia;
  }, [tipLicencia]);

  useEffect(() => {
    switch (usuario.rol) {
      case "grlc":
        //console.log(tipolicencia);
        setTipoLicencia("1");
        break;
      case "gblc":
        setTipoLicencia("2");
        break;
      case "ingresos":
        setTipoLicencia("3");
        break;
      default:
        setTipoLicencia("0");
        setSoloLectura(false);
        break;
    }
  }, [usuario]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col sm={12}>
          <Form.Group>
            <Form.Label>Contribuyentes</Form.Label>
            <div className="ui-dropdown">
              <Dropdown
                placeholder="Buscar contribuyente"
                fluid
                search
                selection
                compact
                options={contribuyentes.map((c) => ({
                  key: c.id,
                  value: c.id,
                  text:
                    c.tipopersona === 1
                      ? `${c.nombre} ${c.apellidoPaterno} ${c.apellidoMaterno}`
                      : `${c.razonSocial}`,
                }))}
                value={contId.ContribuyenteId}
                onChange={(e, data) => {
                  setContribuyenteId({
                    ...contId,
                    ContribuyenteId: data.value,
                  });
                }}
              />
            </div>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Label>Negocios / Propiedades del contribuyente</Form.Label>
          <div className="ui-dropdown">
            <Dropdown
              className="ui-dropdown"
              placeholder="Negocios/Propiedades"
              fluid
              search
              selection
              options={
                negocios.length === 0
                  ? null
                  : negocios.map((n) => ({
                      key: n.id,
                      value: n.id,
                      text: n.razonsocial,
                    }))
              }
              value={negoId.NegocioId}
              onChange={(e, data) => {
                setNegocioId({
                  negoId,
                  NegocioId: data.value,
                });
              }}
            />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Tipo de expedición</Form.Label>
            <Form.Control
              as="select"
              name="tipopersona"
              value={tipopersona}
              onChange={handleChange}
            >
              <option value={0}>Seleccione</option>
              <option value={1}>Persona Física</option>
              <option value={2}>Persona Moral</option>
              <option value={3}>Riviera del rio</option>
              <option value={4}>Enramada</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={6}>
          {usuario.rol === "administrador" ? (
            <Form.Group>
              <Form.Label>Tipo de Licencia</Form.Label>
              <Form.Control
                as="select"
                readOnly={sololectura}
                value={tipLicencia}
                onChange={(e) => {
                  setTipoLicencia(e.target.value);
                }}
              >
                <option value={0}>Seleccione</option>
                <option value={1}>Giro Rojo</option>
                <option value={2}>Giro Blanco</option>
                <option value={3}>Anuncios</option>
              </Form.Control>
            </Form.Group>
           ) : null}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={8}>
          <Form.Label>Actividad</Form.Label>
          <Form.Control
            name="actividad"
            value={actividad}
            onChange={handleChange}
            onBlur={handleBlur}
          ></Form.Control>
        </Col>
        <Col sm={4}>
          <Form.Label>Horario</Form.Label>
          <Form.Control
            name="horario"
            value={horario}
            onChange={handleChange}
            onBlur={handleBlur}
          ></Form.Control>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="descripcion"
            value={descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Form.Row>
      <Formu.Group>
        <Col>
          <Button type="submit">Guardar</Button>
        </Col>
      </Formu.Group>
    </Form>
    
  );
}

export default AddLicencia;
