import React, { useState, useEffect } from "react";

const useValidacion = (stateInicial, validar, fn) => {
  const [submitForm, setSubmitForm] = useState(false);
  const [valores, guardarValores] = useState(stateInicial);
  const [errores, guardarErrores] = useState({});

  useEffect(() => {
    if (submitForm) {
      const noerrores = Object.keys(errores).length === 0;
      if (noerrores) {
        //ejecutamos todo normal
        fn();
      }
      setSubmitForm(false);
    }
  }, [errores, fn, submitForm]);

  //Función que se ejecuta cuando el usuario escribe algo
  const handleChange = (e) => {
    
    guardarValores({
      ...valores,
      [e.target.name]: e.target.value,
    });
  };

  //cuando se realiza el evento blur
  const handleBlur = () => {
    const erroresvalidacion = validar(valores);
    guardarErrores(erroresvalidacion);
  };

  //Validamos cuando el usuario ejecute el evento de envío
  const handleSubmit = (e) => {
    e.preventDefault();
    const erroresvalidacion = validar(valores);
    guardarErrores(erroresvalidacion);
    setSubmitForm(true);
  };

  //retornamos
  return {
    valores,
    errores,
    handleBlur,
    handleChange,
    handleSubmit,
  };
};

export default useValidacion;
