import React from "react";
import SectionTitle from "../../../../components/section-title";
// import { Form, Col } from "react-bootstrap";
const AgregarContribuyente = () => {
  return (
    <>
      <SectionTitle title="Contribuyente" subtitle="Nuevo contribuyente" />
      <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4">
        <div className="w-full ">
          {/* Componente para la información básica del contribuyente */}
          <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4"></div>
          {/* <Form>
            <Form.Group>
              <Form.Label>Tipo persona</Form.Label>
              <Form.Control as="select" name="tipopersona">
                <option value={0}>Seleccione una opción</option>
                <option value={1}>Persona física</option>
                <option value={2}>Persona moral</option>
              </Form.Control>
            </Form.Group>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre (s)</Form.Label>
                  <Form.Control placeholder="Nombre" name="nombre" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Apellido paterno</Form.Label>
                  <Form.Control placeholder="Nombre" name="nombre" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Apellido Matero</Form.Label>
                  <Form.Control placeholder="Nombre" name="nombre" />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>CURP</Form.Label>
                  <Form.Control placeholder="C.U.R.P" name="CURP" />
                </Form.Group>
              </Col>{" "}
              <Col>
                <Form.Group>
                  <Form.Label>RFC</Form.Label>
                  <Form.Control placeholder="RFC" name="rfc" />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form> */}
        </div>
      </div>
    </>
  );
};

export default AgregarContribuyente;
