import React from "react";
import Table from "react-tailwind-table";
const ListadoBusqueda = ({ resultados }) => {
  const columnas = [
    {
      field: "nombre",
      use: "Contribuyente",
    },
    {
      field: "cuenta_predial",
      use: "Cuenta Predial",
    },
    {
      field: "pago",
      use: "Estado",
    },
  ];
  const rowcheck = (row, column) => {
    if (column.field === "pago") {
      return row.pago ? <p>Pagado</p> : <p>Adeudo</p>;
    }
  };
  if (resultados.length === 0)
    return (
      <Table
        columns={columnas}
        rows={resultados}
        show_search={false}
        no_content_text={"No hay contenido disponible"}
        export_text={"Exportar a Excel"}
      />
    );
  return (
    <Table
      columns={columnas}
      rows={resultados}
      row_render={rowcheck}
      show_search={false}
      export_text={"Exportar a Excel"}
      export_csv_file={"morosos.csv"}
    />
  );
};

export default ListadoBusqueda;
