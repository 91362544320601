import React from "react";
import { Switch, Route } from "react-router-dom";
import Contribuyentes from "../pages/Modulos/Contribuyente";
import AgregarContribuyente from "../pages/Modulos/Contribuyente/AgregarContribuyente";
import RutaPrivada from "./RutaPrivada";
import Login from "../pages/Login";
import Negocio from "../pages/Modulos/Negocio/Negocio";
import Test from "../pages/test";
import Licencias from "../pages/Modulos/Licencias/Licencias";
//Apps
import Catastro from "../pages/Herramientas/Catastro";
const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <RutaPrivada exact path="/home" component={Test} />
      <RutaPrivada exact path="/modulo/negocios" component={Negocio} />
      <RutaPrivada
        exact
        path="/modulo/contribuyente"
        component={Contribuyentes}
      />
      <RutaPrivada
        exact
        path="/modulo/contribuyente/nuevo"
        component={AgregarContribuyente}
      />
      <RutaPrivada exact path="/apps/catastro" component={Catastro} />
      <RutaPrivada exact path="/modulo/licencias" component={Licencias} />
    </Switch>
  );
};
export default Routes;
