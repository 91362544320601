import React, { useReducer } from "react";
import contribuyenteReduce from "./contribuyenteReduce";
import localApi from "../../config/Config";
import ContribuyenteContext from "./contribuyenteContext";
import {
  BUSCAR_CONTRIBUYENTE,
  LISTAR_CONTRIBUYENTE,
  REGISTRAR_CONTRIBUYENTE,
  OBTEN_CONTRIBUYENTE,
} from "../../Types";
const ContribuyenteState = (props) => {
  const initialState = {
    contribuyentes: [],
    contribuyente: [],
    resultado: [],
  };
  const [state, dispatch] = useReducer(contribuyenteReduce, initialState);

  //Obtenemos a los contribuyentes
  const obtenerContribuyentes = async (id, token) => {
    try {
      const result = await localApi.get(`/contribuyentes/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: LISTAR_CONTRIBUYENTE,
        payload: result.data.contribuyentes,
      });
    } catch (error) {}
  };

  //Obtenemos los resultados de busqueda
  const busquedaContribuyente = async (id, token, busqueda) => {
    try {
      //console.log(id, token, busqueda);
      const result = await localApi.post(
        `/contribuyentes/search/${id}`,
        busqueda,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: BUSCAR_CONTRIBUYENTE,
        payload: result.data.consulta,
      });
    } catch (error) {
      return error;
    }
  };

  //Obtenemos la información de un contribuyente
  const obtenerContribuyente = async (id, token, idcliente) => {
    try {
      const result = await localApi.get(
        `/contribuyentes/contribuyente/${id}?idcliente=${idcliente}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: OBTEN_CONTRIBUYENTE,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Crear contribuyente
  const crearContribuyente = async (id, token, datos) => {
    try {
      const result = await localApi.post(`/contribuyente/${id}`, datos, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      //console.log(result);
      dispatch({
        type: REGISTRAR_CONTRIBUYENTE,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };
  //Editar contribuyente
  const editarContribuyente = async (id, token, cid, datos) => {
    try {
      const result = await localApi.put(`/contribuyente/${cid}/${id}`, datos, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: REGISTRAR_CONTRIBUYENTE,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <ContribuyenteContext.Provider
      value={{
        contribuyentes: state.contribuyentes,
        contribuyente: state.contribuyente,
        resultado: state.resultado,
        obtenerContribuyentes,
        busquedaContribuyente,
        crearContribuyente,
        editarContribuyente,
        obtenerContribuyente,
      }}
    >
      {props.children}
    </ContribuyenteContext.Provider>
  );
};

export default ContribuyenteState;
