import React, { useContext, useEffect } from "react";
import { Router, Redirect, Route } from "react-router-dom";
import AuthContext from "../context/Auth/authContext";
const RutaPrivada = ({ component: Component, ...props }) => {
  const authContext = useContext(AuthContext);
  const { autenticado, UsuarioAutenticado } = authContext;
  useEffect(() => {
    UsuarioAutenticado();
  }, []);
  
  
  return (
    
    <Route
      {...props}
      render={(props) =>
        !autenticado ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export default RutaPrivada;
