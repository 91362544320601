import {
  EDITA_DIRECCION,
  OBTENER_ESTADOS,
  OBTENER_LOCALIDADES,
  OBTENER_MUNICIPIOS,
  OBTEN_DIRECCION,
  REGISTRA_DIRECCION,
} from "../../Types/index";

export default (state, action) => {
  switch (action.type) {
    case REGISTRA_DIRECCION:
      return {
        ...state,
        direccion: action.payload,
      };
    case OBTENER_LOCALIDADES:
      return {
        ...state,
        localidad: action.payload,
      };
    case OBTEN_DIRECCION:
      return {
        ...state,
        direcciones: action.payload,
      };
    case OBTENER_ESTADOS:
      return {
        ...state,
        estados: action.payload,
      };
    case OBTENER_MUNICIPIOS:
      return {
        ...state,
        municipios: action.payload,
      };
    case EDITA_DIRECCION: {
      return {
        ...state,
        mensaje: action.payload,
      };
    }
    default:
      return state;
  }
};
