import React from "react";
import { FiActivity, FiCompass, FiDatabase, FiFile } from "react-icons/fi";

const initialState = [
  {
    title: "Inicio",
    items: [
      {
        url: "/home",
        icon: <FiCompass size={20} />,
        title: "Inicio",
        items: [],
      },

      {
        url: "/1",
        icon: <FiDatabase size={20} />,
        title: "Catálogos",
        items: [
          {
            url: "/modulo/contribuyente",
            administrador: "administrador",
            title: "Contribuyente",
            items: [
             
            ],
          },
          {
            url: "/modulo/negocios",
            title: "Negocios",
            items: [],
          },
        ],
      },
      {
        url: "/",
        icon: <FiFile size={20} />,
        title: "Módulos",
        items: [
          {
            url: "/modulo/licencias",
            title: "Licencias",
            items: [],
          },
        ],
      },
    ],
  },
];

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
