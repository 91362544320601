import React, { useState } from "react";
import LicenciasState from "../../../context/Licencias/LicenciasState";
import KeyboardEventHandler from "react-keyboard-event-handler";
import MenuLicencias from "../../../components/Licencias/Menu";

//Opciones
import ListadoLicencia from "../../../components/Licencias/Listado/ListadoLicencia";
import CancelarLicencia from "../../../components/Licencias/CancelaLicencia";

const Licencias = () => {
  const [open, setOpen] = useState(false);
  const [opciones, setOpciones] = useState("llistado");

  //Crear contribuyente
  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <LicenciasState>
        <KeyboardEventHandler handleKeys={["alt+n"]} onKeyEvent={openModal} />
        <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
          <div className="flex items-start justify-start w-full">
            <div className="flex-shrink-0 w-64 p-4">
              <MenuLicencias opciones={opciones} setOpciones={setOpciones} />
            </div>
            <div className="w-full flex flex-col p-4">
              {
                {
                  llistado: <ListadoLicencia />,
                  lcancelar: <CancelarLicencia />,
                }[opciones]
              }
            </div>
          </div>
        </div>
        {/* <AddLicencia title="Crear licencia" setOpen={setOpen} open={open} /> */}
      </LicenciasState>
      {/* <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
        <div className="flex items-start justify-start w-full">
          <ListadoLicencia />
        </div>
      </div> */}
    </>
  );
};

export default Licencias;
