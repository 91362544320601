import { LOGIN_EXITOSO, OBTENER_USUARIO } from "../../Types/index";
import { ACCESS_TOKEN } from "../../Constants/Contantes";

export default (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
      
      return {
        ...state,
        autenticado: true,
        token: localStorage.getItem(ACCESS_TOKEN)
      };
    case OBTENER_USUARIO:
      return {
        ...state, 
        autenticado:true,
        usuario: action.payload
      }
    default:
      return state;
  }
};
