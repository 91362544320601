import { element } from "prop-types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
const Barras = ({ grafico1 }) => {
  const [messs, setMes] = useState({
    id: 0,
    meses: "",
    tramites: "",
  });

 console.log(grafico1)
  useEffect(() => {
    let meses = [];
    let tramites = [];
    let id=[]
    grafico1.map((x) => {
      id.push(x.mesnumber)
      meses.push(x.Mes.toUpperCase());
      tramites.push(x.emitidos);
    });
    setMes({
      meses: meses,
      tramites: tramites,
    });
  }, [grafico1]);


  const data = {
    labels: messs.meses,

    datasets: [
      {
        key: messs.id,
        label: "Total de Trámites mensuales",
        data: messs.tramites,
        backgroundColor: "#FF0000",
      },
    ],
  };

  return <Bar data={data} height={100} />;
};

export default Barras;
