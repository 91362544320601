import React from "react";
import { FiHome } from "react-icons/fi";
import { Link } from "react-router-dom";
const Apps = () => {
  const items = [
    {
      title: "Catastro",
      icon: <FiHome className="stroke-current text-xl text-grey-700" />,
      url: "/apps/catastro",
    },
  ];
  return (
    <>
      <div className="dropdown-title">Apps</div>
      <div className="flex flex-wrap text-center">
        {items.map((item, i) => (
          <Link
            to={item.url}
            key={i}
            className="w-1/3 flex flex-col items-center justify-center h-20 space-y-1 dropdown-item"
          >
            {item.icon}
            <span className="text-xs">{item.title}</span>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Apps;
