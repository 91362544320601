import React, { useState, useContext, useEffect } from "react";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import LicenciasContext from "../../../context/Licencias/LicenciasContext";
import AuthContext from "../../../context/Auth/authContext";
import { Standar } from "../../Tools/Standar/Standar";
const CancelarLicencia = () => {
  //Contextos
  const lContext = useContext(LicenciasContext);
  const AuthCtx = useContext(AuthContext);

  const { licencia, buscarFolio, cancelarLicencia } = lContext;
  const { token, usuario } = AuthCtx;

  //States Iniciales
  const [busqueda, setBusqueda] = useState({
    criterio: "",
  });

  const [datos, setDatos] = useState({
    folio: "",
    contribuyente: "",
    negocio: "",
    motivo: "",
    id: "",
  });

  //Buscar licencia
  const BuscarLicencia = (e) => {
    e.preventDefault();
    if (busqueda.criterio.length === 0) {
      Standar(
        "Error",
        "error",
        "El campo buscar licencia no puede estar vacio"
      );
    } else {
      buscarFolio(usuario.id, token, busqueda.criterio);
    }
  };

  useEffect(() => {
    if (licencia.consulta === undefined || licencia.code == 404) {
    } else {
      licencia.consulta.folio = "";
      licencia.consulta.contribuyente = "";
      licencia.consulta.razonSocial = "";
      licencia.consulta.negocio = "";
      licencia.consulta.id = "";
    }
  }, []);

  //Cancelar Licencia
  const CancelarLicencia = (e) => {
    e.preventDefault();
    cancelarLicencia(usuario.id, token, datos);
    borrarCampos();
  };

  useEffect(() => {
    switch (licencia.code) {
      case 500:
        Standar("Error", "error", licencia.message);
        break;
      case 200:
        if (licencia.consulta === undefined) {
          setDatos({
            folio: "",
            contribuyente: "",
            negocio: "",
          });
        } else {
          if (licencia.consulta.length === 0) {
            //campos nulos
            borrarCampos();
          } else {
            setDatos({
              folio: licencia.consulta.folio,
              contribuyente:
                licencia.consulta.tipopersona === 1
                  ? licencia.consulta.contribuyente
                  : licencia.consulta.razonSocial,
              negocio: licencia.consulta.negocio,
              id: licencia.consulta.id,
            });
          }
        }
        break;
      // case 404:
      //   Standar(
      //     "Información",
      //     "info",
      //     `No se encontró información con el criterio busqueda ingresado, FOLIO INGRESADO: ${busqueda.criterio}`
      //   );

      //   break;
    }
  }, [licencia]);

  function borrarCampos() {
    setDatos({
      folio: "",
      contribuyente: "",
      negocio: "",
      motivo: "",
    });

    setBusqueda({
      criterio: "",
    });
  }

  return (
    <>
      <h2>Cancelar licencias</h2>
      <p>Te permite cancelar una licencia de funcionamiento</p>
      <Form onSubmit={BuscarLicencia}>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Buscar licencia"
            placeholder="Ingrese el folio tal como aparece en la licencia Ejemplo: GR - 1 y presiona la tecla Enter para buscar"
            value={busqueda.criterio}
            onChange={(e) =>
              setBusqueda({
                ...busqueda,
                criterio: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form>
      <Form onSubmit={CancelarLicencia}>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Folio"
            readOnly
            value={datos.folio}
            onChange={(e) => {
              setDatos({
                ...datos,
                folio: e.target.value,
              });
            }}
          />
          <Form.Field
            control={Input}
            label="Contribuyente"
            readOnly
            value={datos.contribuyente}
          />
          <Form.Field
            control={Input}
            label="Negocio"
            readOnly
            value={datos.negocio}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={TextArea}
            label="Motivo de cancelación"
            value={datos.motivo}
            onChange={(e) => {
              setDatos({
                ...datos,
                motivo: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group>
          <Button type="submit">Aceptar</Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default CancelarLicencia;
