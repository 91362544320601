import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import AddLicencia from "../../../components/Licencias/AddLicencia";
const MenuLicencias = ({ opciones, setOpciones }) => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("llistado");
  const prueba = (e) => {
    setOpen(true);
    // <AddLicencia title="Crear licencia" setOpen={setOpen} open={open} />
    //alert("x");
  };

  const menuOpciones = (opcion) => {
    setOpciones(opcion);
    setActiveItem(opcion);
  };

  return (
    <>
      <Menu text vertical>
        <Menu.Item>
          <Menu.Header>Licencias</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="llistado"
              active={activeItem === "llistado"}
              onClick={(e) => {
                menuOpciones("llistado");
              }}
            >
              Catálogo de Licencias
            </Menu.Item>
            <Menu.Item name="nlicencia" onClick={prueba}>
              Nueva Licencia
            </Menu.Item>
            <Menu.Item
              name="lcancelar"
              active={activeItem === "lcancelar"}
              onClick={() => {
                menuOpciones("lcancelar");
              }}
            >
              Cancelar Licencia
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Menu>
      <AddLicencia title="Crear licencia" setOpen={setOpen} open={open} />
    </>
  );
};

export default MenuLicencias;
