import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Widget from "../../../../components/Widget";
import * as Yup from "yup";
import { useFormik } from "formik";
// GRAPHQL
import { useMutation } from "@apollo/client";
import { PREDIO_PAGADO_QUERY } from "../../../../graphql/Catastro_GQL";
const BusquedaPredial = ({ setResultados }) => {
  //Graphql Busquéda
  const [predioPagado] = useMutation(PREDIO_PAGADO_QUERY);
  //Formik
  const formik = useFormik({
    initialValues: {
      cadena: "",
      tipoBusqueda: "",
    },
    validationSchema: Yup.object({
      cadena: Yup.string().required("Los datos de búsqueda son requeridos"),
      tipoBusqueda: Yup.string().required(
        "El criterio de búsqueda es requerido"
      ),
    }),
    onSubmit: async (datos) => {
      const { tipoBusqueda, cadena } = datos;
      try {
        const { data } = await predioPagado({
          variables: {
            tipoBusqueda,
            cadena,
          },
        });
        setResultados(data.predioPagado);
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <Widget>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg={6}>
            <Form.Group as={Row}>
              <Col xs={12}>
                <Form.Control
                  placeholder="Ingrese información de búsqueda"
                  className="form-input "
                  name="cadena"
                  value={formik.values.cadena}
                  onChange={formik.handleChange}
                />
                <p className="text-danger">{formik.errors.cadena}</p>
              </Col>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group as={Row}>
              <Col xs={6}>
                <Form.Control
                  as="select"
                  className="form-input"
                  name="tipoBusqueda"
                  value={formik.values.tipoBusqueda}
                  onChange={formik.handleChange}
                >
                  <option value="0">Criterio de búsqueda</option>
                  <option value="1">Cuenta predial</option>
                  <option value="2">Nombre de contribuyente</option>
                </Form.Control>
                <p className="text-danger">{formik.errors.tipoBusqueda}</p>
              </Col>
              <Col xs={2}>
                <Button type="submit">Buscar</Button>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Widget>
  );
};

export default BusquedaPredial;
