import React, { useEffect, useRef, useState, useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import { useSelector, shallowEqual } from "react-redux";
import { Form, Col, Button, Tab, Row, Nav } from "react-bootstrap";
import vnegocio from "../../../validator/negocio";
import Portal from "../../poral";
import { FiX } from "react-icons/fi";
import Danger from "../../Alertas/Danger";
import Direccion from "../../Direccion";
import NegocioContext from "../../../context/Negocios/negociosContext";
import useValidation from "../../../Hooks/UseValidation";
import ContribuyenteContext from "../../../context/Contribuyente/contribuyenteContext";
import DireccionContext from "../../../context/Direccion/direccionContext";
const EditarNegocio = ({
  open,
  setOpen,
  title,
  uid,
  token,
  actualizar,
  setActualizar,
}) => {
  const nContext = useContext(NegocioContext);
  const dContexto = useContext(DireccionContext);
  const cContext = useContext(ContribuyenteContext);

  const { negocio, editarNegocio } = nContext;
  const { contribuyentes } = cContext;
  const { obtenDireccion } = dContexto;

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };
  const modalRef = useRef(null);
  const hide = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      console.log(modalRef.current.contains(event.target));
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const STATE_INICIAL = {
    id: negocio.id,
    razonsocial: negocio.razonsocial,
    rfc: negocio.rfc,
    tipocomercio: negocio.tipocomercio,
    ContribuyenteId: negocio.ContribuyenteId,
  };

  const {
    valores,
    errores,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useValidation(STATE_INICIAL, vnegocio, formRegister);

  //Formulario para registrar
  async function formRegister() {
    setActualizar(true);
    editarNegocio(uid, token, negocio.id, valores);
  }

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <Formulario
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    errores={errores}
                    valores={valores}
                    contribuyentes={contribuyentes}
                    setActualizar={setActualizar}
                    actualizar={actualizar}
                    token={token}
                    userid={uid}
                  />
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

function Formulario(props) {
  const {
    valores,
    handleChange,
    handleSubmit,
    handleBlur,
    errores,
    contribuyentes,
    setActualizar,
    actualizar,
    token, 
    userid
  } = props;
  const { razonsocial, tipocomercio, rfc, ContribuyenteId } = valores;

  console.log(valores);

  const [contId, setContribuyenteId] = useState({
    ContribuyenteId: ContribuyenteId,
  });

  useEffect(() => {
    valores.ContribuyenteId = contId.ContribuyenteId;
  }, [ContribuyenteId, contId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Tab.Container id="tabcontainer" defaultActiveKey="negocioBasico">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="negocioBasico">Datos Básicos</Nav.Link>
                <Nav.Link eventKey="negocioDireccion">Dirección</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="negocioBasico">
                <Form.Row>
                  <Col sm={8}>
                    <Form.Group>
                      <Form.Label>Contribuyente/Dueño</Form.Label>
                      <Dropdown
                        placeholder="Buscar contribuyente"
                        fluid
                        search
                        selection
                        options={contribuyentes.map((c) => ({
                          key: c.id,
                          value: c.id,
                          text:
                            c.tipopersona === 1
                              ? `${c.nombre} ${c.apellidoPaterno} ${c.apellidoMaterno}`
                              : `${c.razonSocial}`,
                        }))}
                        value={contId.ContribuyenteId}
                        onChange={(e, data) => {
                          setContribuyenteId({
                            ...contId,
                            ContribuyenteId: data.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>Tipo de negocio</Form.Label>
                      <Form.Control
                        as="select"
                        name="tipocomercio"
                        value={tipocomercio}
                        onChange={handleChange}
                      >
                        <option value={0}>Seleccione</option>
                        <option value="LM">Negocio Ambulante/móvil</option>
                        <option value="LF">Local Fijo</option>
                        <option value="LSF">Local Semifijo</option>
                      </Form.Control>
                      {errores.tipocomercio && (
                        <Danger
                          variante="danger"
                          mensaje={errores.tipocomercio}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Nombre/Razón Social</Form.Label>
                      <Form.Control
                        placeholder="Razón social"
                        name="razonsocial"
                        value={razonsocial}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                    {errores.razonsocial && (
                      <Danger variante="danger" mensaje={errores.razonsocial} />
                    )}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>RFC</Form.Label>
                      <Form.Control
                        placeholder="RFC"
                        name="rfc"
                        value={rfc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                    {errores.rfc && (
                      <Danger variante="danger" mensaje={errores.rfc} />
                    )}
                  </Col>
                </Form.Row>
              </Tab.Pane>
              <Tab.Pane eventKey="negocioDireccion">
                <Direccion
                  nid={valores.id}
                  setActualizar={setActualizar}
                  actualizar={actualizar}
                  //setNid={setNid}
                  userid={userid}
                  token={token}
                  //tipo={tipo}
                  //setTipo={setTipo}
                />
              </Tab.Pane>
              <Col>
                <Button type="submit">Editar</Button>
              </Col>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Form>
    
  );
}

export default EditarNegocio;
