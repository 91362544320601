import React from "react";
import { useLocation } from "react-router-dom";
import Centro from "./centro/centro";
import Empty from './empty'
import Layout1 from "./layout-1";
const Layouts = ({ children }) => {
  let location = useLocation();
  let { pathname } = { ...location };
  if (["/"].includes(pathname)) {
    return <Centro>{children}</Centro>;
  } /*else if (["/landing", "/login-2", "/login-3"].includes(pathname)) {
    // return <Empty>{children}</Empty>
  }*/ else {
    return <Layout1>{children}</Layout1>;
  }
};

export default Layouts;
