import React, { useEffect, useContext } from "react";
import { useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
import { FiDelete, FiEdit } from "react-icons/fi";
import ContribuyenteContext from "../../../context/Contribuyente/contribuyenteContext";
import DireccionContext from "../../../context/Direccion/direccionContext";
import Datatable from "../../DataTable";
import EditarContribuyente from "../EditarContribuyente/EditarContribuyente";
import { toast } from "react-toastify";
//Implementación Graphql
import { useQuery } from "@apollo/client";
import { OBTENER_CONTRIBUYENTES } from "../../../graphql/Contribuyente";
import { Container, Row, Col } from "react-bootstrap";
const ListadoContribuyente = ({ usuario, token }) => {
  const contexto = useContext(ContribuyenteContext);
  const dContexto = useContext(DireccionContext);
  const [actualizar, setActualizar] = useState(false);
  const {
    contribuyentes,
    obtenerContribuyentes,
    obtenerContribuyente,
    contribuyente,
    editarContribuyente,
    resultado,
  } = contexto;

  //GraphQL
  const { data, loading, error } = useQuery(OBTENER_CONTRIBUYENTES);
  console.log(data);
  const { obtenDireccion } = dContexto;

  const [abrirModal, setAbrirModal] = useState(false);

  const [cid, setCid] = useState(null);
  const columnas = [
    {
      Header: "Nombre(s)",
      accessor: "nombre",
    },
    {
      Header: "Apellidos",
      accessor: "apellidoPaterno",
    },
    {
      Header: "Apellidos",
      accessor: "apellidoMaterno",
    },
    {
      Header: "Razón Social",
      accessor: "razonSocial",
    },

    {
      Header: "Editar",
      accessor: "id",
      Cell: (props) =>
        props.value ? (
          <Opciones id={props.value} openModal={openModal} />
        ) : null,
    },
  ];

  useEffect(() => {
    contribuyente.length === 0 ? setAbrirModal(false) : setAbrirModal(true);
  }, [contribuyente]);

  function openModal(id) {
    setCid(id);
    //setActualizar(true);
    obtenerContribuyente(usuario.id, token, id);
    obtenDireccion(usuario.id, token, id);
  }

  useEffect(() => {
    toast.success("hola mundo");
    obtenerContribuyentes(usuario.id, token);
  }, []);

  if (loading) return <h1>Cargando información...</h1>;
  if (error) return <h1>Ocurrió un error al procesar la solicitud</h1>;

  return (
    <>
      {abrirModal === true ? (
        <EditarContribuyente
          title="Editar"
          open={abrirModal}
          setOpen={setAbrirModal}
          setActualizar={setActualizar}
          actualizar={actualizar}
          contribuyente={contribuyente}
          usuarioid={usuario.id}
          token={token}
          editarContribuyente={editarContribuyente}
          resultado={resultado}
        />
      ) : null}
      <Datatable
        columns={columnas}
        data={contribuyentes}
        usuarioid={usuario.id}
        token={token}
        description={"Catálogo de contribuyentes"}
        titulo={"Contribuyentes activos"}
      />
    </>
  );
};

function Opciones({ id, openModal }) {
  return (
    <>
      <Container>
        <Row>
          <Col md="auto">
            <FiEdit size={14} onClick={() => openModal(id)} />
          </Col>
          <Col md="auto">
            <FiDelete size={14} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListadoContribuyente;
