import React, { useContext, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import MenuIzquierdo from "../../components/MenuLateral";
import Navbar1 from "../../components/Navbar/Navbar";
import "../../css/layouts/layout-1.css";
import AuthContext from "../../context/Auth/authContext";
const Layout1 = ({ children }) => {
  const { config, palettes } = useSelector(
    (state) => ({
      config: state.config,
      palettes: state.palettes,
    }),
    shallowEqual
  );
  const { layout, collapsed } = { ...config };
  let { background, navbar, logo, leftSidebar, rightSidebar } = {
    ...palettes,
  };

  const authContext = useContext(AuthContext);
  const { UsuarioAutenticado } = authContext;

  useEffect(() => {
    UsuarioAutenticado();
  }, []);

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
      className={`${background === "dark" ? "dark-mode" : "default-mode"}`}
    >
      {/*<Loader />*/}
      {/* <RightSidebar1 /> */}
      <div className="wrapper">
        <MenuIzquierdo />
        <div className="main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white">
          <Navbar1 />
          <div className="min-h-screen w-full p-4">{children}</div>
        </div>
      </div>
    </div>
  );

};
export default Layout1;
