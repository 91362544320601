import {
  BUSCAR_FOLIO,
  CANCELAR_LICENCIA,
  CREAR_LICENCIA,
  IMPRIMIR_LICENCIA,
  MSG_LICENCIA,
  OBTENER_LICENCIAS,
} from "../../Types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_LICENCIAS:
      return {
        ...state,
        licencias: action.payload,
      };
    case CREAR_LICENCIA:
      return {
        ...state,
        informacion: action.payload,
      };
    case IMPRIMIR_LICENCIA:
      return {
        ...state,
        urlprint: action.payload,
      };
    case BUSCAR_FOLIO:
      return {
        ...state,
        licencia: action.payload,
      };
    case CANCELAR_LICENCIA:
      return {
        ...state,
        informacion: action.payload,
      };
    default:
      return state;
  }
};
