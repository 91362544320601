import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/Auth/authContext";
import { Button } from "react-bootstrap";
import DireccionState from "../../../context/Direccion/direccionState";
import ListadoNegocios from "../../../components/Negocios/Listado";
import NegociosState from "../../../context/Negocios/negociosState";
import AgregarNegocio from "../../../components/Negocios/AgregarNegocio/AgregarNegocio";

const Negocio = () => {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);
 
  const { UsuarioAutenticado, usuario, token } = authContext;
  
  const [negocioSearch, setNegocioSearch] = useState(true);
  useEffect(() => {
    
    UsuarioAutenticado();
  }, []);

 

  //Crear negocio
  const openModal = () => {
    setOpen(true);
  };
  return (
    <>
      <DireccionState>
        <NegociosState>
          <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
            <div className="flex items-start justify-start w-full">
              <div className="flex-shrink-0 w-64 p-4">
                <div className="mb-4">
                  <Button
                    className="btn btn-default btn-block rounded bg-blue-500 hover:bg-blue-600 text-white block text-center"
                    onClick={openModal}
                  >
                    Nuevo
                  </Button>
                </div>
                {/* Aquí inserta el menú de navegación*/}
              </div>
              <div className="w-full flex flex-col p-4">
                <ListadoNegocios
                  UsuarioAutenticado={UsuarioAutenticado}
                  usuario={usuario}
                  token={token}
                  negocioSearch={negocioSearch}
                  setNegocioSearch={setNegocioSearch}
                />
              </div>
            </div>
          </div>
        <AgregarNegocio
          title="Registrar nuevo negocio"
          setOpen={setOpen}
          open={open}
          usuario={usuario}
          token={token}
        />
        
        </NegociosState>
      </DireccionState>
    </>
  );
};

export default Negocio;
