import React, { useContext, useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import ContribuyenteContext from "../../../context/Contribuyente/contribuyenteContext";
import AuthContext from '../../../context/Auth/authContext';
const BuscarContribuyente = () => {
  const contexto = useContext(ContribuyenteContext);
  const authContext = useContext(AuthContext);
  const { contribuyentes, busquedaContribuyente } = contexto;
  const { UsuarioAutenticado, usuario, token } = authContext;
  const [data, setBusqueda] = useState({
    busqueda: "",
    tipo: 0,
  });

  const Buscar = (e) => {
    e.preventDefault();
    busquedaContribuyente(usuario.id,token,data)
  };

  return (
    <Form onSubmit={Buscar}>
      <Row>
        <Col lg={6}>
          <Form.Group as={Row}>
            <Col xs={12}>
              <Form.Control
                placeholder="Ingrese clave de busqueda"
                className="form-input "
                value={data.busqueda}
                name="busqueda"
                onChange={(e) =>
                  setBusqueda({ ...data, busqueda: e.target.value })
                }
              />
            </Col>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group as={Row}>
            <Col xs={10}>
              <Form.Control
                as="select"
                className="form-input"
                name="tipo"
                value={data.tipo}
                onChange={(e) => {
                  setBusqueda({ ...data, tipo: e.target.value });
                }}
              >
                <option value="1">Nombre de Contribuyente</option>
                <option value="2">Razón Social</option>
                <option value="3">RFC</option>
                <option value="4">CURP</option>
              </Form.Control>
            </Col>
            <Col xs={2}>
              <Button type="submit">Buscar</Button>
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Form>
    
  );
};

export default BuscarContribuyente;
