import React, { useReducer } from "react";
import negociosReduce from "./negociosReduce";
import localApi from "../../config/Config";
import NegociosContext from "./negociosContext";
import {
  OBTENER_NEGOCIOS,
  BUSCAR_NEGOCIOS,
  REGISTRAR_NEGOCIO,
  OBTENER_NEGOCIO,
  NEGOCIO_CONTRIBUYENTE,
} from "../../Types";

const NegociosState = (props) => {
  const initialState = {
    negocios: [],
    negocio: [],
    mensaje: "",
  };
  const [state, dispatch] = useReducer(negociosReduce, initialState);

  //Obtener el listado de los negocios
  const obtenerNegocios = async (id, token) => {
    try {
      const result = await localApi.get(`/negocios/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: OBTENER_NEGOCIOS,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Obtenemos la información de un negocio
  const obenerNegocio = async (id, token, idnegocio) => {
    try {
      const result = await localApi.get(
        `/negocios/negocio/${id}?idnegocio=${idnegocio}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: OBTENER_NEGOCIO,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Buscamos un negocio
  const buscarNegocio = async (id, token, busqueda) => {
    try {
      const result = await localApi.post(`/negocios/buscar/${id}`, busqueda, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: BUSCAR_NEGOCIOS,
        payload: result.data.consulta,
      });
    } catch (error) {
      return error;
    }
  };

  //Obtemos el  negocio del contribuyente
  const negocioContribuyente = async (id, token, contribuyenteid) => {
    try {
      const result = await localApi.get(
        `/negocios/contribuyente/${id}?ContribuyenteId=${contribuyenteid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: NEGOCIO_CONTRIBUYENTE,
        payload: result.data,
      });
    } catch (error) {}
  };

  //Creamos un nuevo negocio
  const crearNegocio = async (id, token, datos) => {
    try {
      const result = await localApi.post(`/negocios/${id}`, datos, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: REGISTRAR_NEGOCIO,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Editamos el negocio
  const editarNegocio = async (id, token, nid, datos) => {
    try {
      const result = await localApi.put(`/negocios/${nid}/${id}`, datos, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: REGISTRAR_NEGOCIO,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <NegociosContext.Provider
      value={{
        negocios: state.negocios,
        negocio: state.negocio,
        mensaje: state.mensaje,
        obtenerNegocios,
        obenerNegocio,
        buscarNegocio,
        crearNegocio,
        editarNegocio,
        negocioContribuyente
      }}
    >
      {props.children}
    </NegociosContext.Provider>
  );
};

export default NegociosState;
