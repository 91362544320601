import React, { useReducer } from "react";
import direccionReduce from "./direccionReduce";
import localApi from "../../config/Config";
import DireccionContext from "./direccionContext";
import {
  REGISTRA_DIRECCION,
  OBTENER_LOCALIDADES,
  OBTENER_MUNICIPIOS,
  OBTEN_DIRECCION,
  OBTENER_ESTADOS,
  EDITA_DIRECCION,
} from "../../Types/index";
const DireccionState = (props) => {
  const initialState = {
    direcciones: [],
    direccion: null,
    localidad: [],
    municipios: [],
    estados: [],
    mensaje: {},
  };
  const [state, dispatch] = useReducer(direccionReduce, initialState);

  //Creamos una nueva dirección
  const crearDireccion = async (id, token, data) => {
    try {
      const result = await localApi.post(`/direccion/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: REGISTRA_DIRECCION,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Editamos la dirección del contribuyente
  const editaDireccion = async (
    id,
    token,
    direccionId,
    ContribuyenteId,
    NegocioId,
    data
  ) => {
    try {
      const result = await localApi.put(
        `/direccion/${id}?direccionId=${direccionId}&cid=${ContribuyenteId}&nid=${NegocioId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
        
      );
    
      dispatch({
        type: EDITA_DIRECCION,
        payload: result.data.message,
      });
    } catch (error) {
      return error;
    }
  };

  //Obtenemos una dirección
  const obtenDireccion = async (id, token, ContribuyenteId, NegocioId) => {
    try {
      const result = await localApi.get(
        `/direccion/${id}?ContribuyenteId=${ContribuyenteId}&NegocioId=${NegocioId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: OBTEN_DIRECCION,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };

  //Obtener localidad
  const obtenerLocalidades = async () => {
    try {
      const result = await localApi.get(`/geografia/localidad`);

      dispatch({
        type: OBTENER_LOCALIDADES,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };
  //Obtener municipios
  const obtenerMunicipios = async () => {
    try {
      const result = await localApi.get(`/geografia/municipios`);

      dispatch({
        type: OBTENER_MUNICIPIOS,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };
  //Obtener estados
  const obtenerEstados = async () => {
    try {
      const result = await localApi.get(`/geografia/estados`);

      dispatch({
        type: OBTENER_ESTADOS,
        payload: result.data,
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <DireccionContext.Provider
      value={{
        direcciones: state.direcciones,
        direccion: state.direccion,
        localidad: state.localidad,
        municipios: state.municipios,
        estados: state.estados,
        mensaje: state.mensaje,
        crearDireccion,
        obtenDireccion,
        obtenerLocalidades,
        obtenerMunicipios,
        obtenerEstados,
        editaDireccion,
      }}
    >
      {props.children}
    </DireccionContext.Provider>
  );
};

export default DireccionState;
