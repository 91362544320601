import React, { useContext, useEffect, useState } from "react";
import ListadoContribuyente from "../../../components/Contribuyente/Listado/ListadoContribuyente";
import MenuContribuyente from "../../../components/Contribuyente/MenuContribuyente/MenuContribuyente";
import AuthContext from "../../../context/Auth/authContext";
import ContribuyenteState from "../../../context/Contribuyente/contribuyenteState";
import { Button } from "react-bootstrap";
import AgregaContribuyente from "../../../components/Contribuyente/AgregaContribuyente/AgregaContribuyente";
import DireccionState from "../../../context/Direccion/direccionState";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { Link } from "react-router-dom";

const Contribuyentes = () => {
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const { UsuarioAutenticado, usuario, token } = authContext;

  useEffect(() => {
    UsuarioAutenticado();
  }, []);

  //Crear contribuyente
  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      {/* {usuario.rol === "administrador" ? ( */}
      <DireccionState>
        <ContribuyenteState>
          <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
            <div className="flex items-start justify-start w-full">
              <div className="flex-shrink-0 w-64 p-4">
                <div className="mb-4">
                  {/* Acciones */}
                  <Link
                    to="/modulo/contribuyente/nuevo"
                    className="btn btn-default btn-block rounded bg-blue-500 hover:bg-blue-600 text-white block text-center"
                  >
                    Nuevo
                  </Link>
                  <Button
                      className="btn btn-default btn-block rounded bg-blue-500 hover:bg-blue-600 text-white block text-center"
                      onClick={openModal}
                    >
                      Nuevo [Alt+n]
                    </Button>
                    {/* <KeyboardEventHandler
                      handleKeys={["alt+n"]}
                      onKeyEvent={openModal}
                    /> */}
                </div>
                {/* <MenuContribuyente
                  UsuarioAutenticado={UsuarioAutenticado}
                  usuario={usuario}
                  token={token}
                /> */}
              </div>
              <div className="w-full flex flex-col p-4">
                <ListadoContribuyente
                  UsuarioAutenticado={UsuarioAutenticado}
                  usuario={usuario}
                  token={token}
                />
              </div>
            </div>
          </div>

          <AgregaContribuyente
            title="Crear contribuyente"
            setOpen={setOpen}
            open={open}
            usuario={usuario}
            token={token}
          />
        </ContribuyenteState>
      </DireccionState>
      {/* // ) : (
      //   <h1>no tienes permitido estar aquí</h1>
      // )} */}
    </>
  );
};

export default Contribuyentes;
