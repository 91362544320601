import React, { useEffect, useRef, useContext, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Form, Col, Button, Tab, Row, Nav } from "react-bootstrap";
import useValidation from "../../../Hooks/UseValidation";
import contribuyente from "../../../validator/contribuyente";
import Portal from "../../poral";
import { FiX } from "react-icons/fi";
import Danger from "../../Alertas/Danger";
import ContribuyenteContext from "../../../context/Contribuyente/contribuyenteContext";
import DireccionState from "../../../context/Direccion/direccionState";
import Direccion from "../../Direccion";
import { toast } from "react-toastify";
import { Standar } from "../../Tools/Standar/Standar";
const STATE_INICIAL = {
  tipopersona: "",
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  razonSocial: "",
  curp: "",
  rfc: "",
  telefono: "",
  celular: "",
  email: "",
};
const AgregaContribuyente = ({ title, open, setOpen, usuario, token }) => {
  const {
    valores,
    errores,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useValidation(STATE_INICIAL, contribuyente, formRegister);
  const [cid, setCid] = useState(null);
  const [actualizar] = useState(false);
  const [tipo, setTipo] = useState(false);

  const ContriContext = useContext(ContribuyenteContext);
  const { crearContribuyente, resultado } = ContriContext;
  const [] = useState(false);
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  async function formRegister() {
    await crearContribuyente(usuario.id, token, valores);
    // console.log(resultado.code)
    // if (resultado?.code === 200) {
    //   toast.success(resultado.message);
    // } else {
    //   toast.error(resultado.message);
    // }
  }

  //setCid(resultado.cid);

  useEffect(() => {
    if (!resultado?.code === 200) {
      Standar("Error", "error", resultado.message);
    } else {
      if (resultado?.cid === undefined || resultado.length===0) {
        //toast.error(resultado.message);
        
       //Standar("Error", "error", resultado.message);
      } else {
        setCid(resultado.cid);
        Standar("Información", "success", resultado.message);
      }
    }
  }, [resultado]);

  const modalRef = useRef(null);
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      console.log(modalRef.current.contains(event.target));
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);
  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <DireccionState>
                    <Formulario
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      valores={valores}
                      errores={errores}
                      cid={cid}
                      setCid={setCid}
                      actualizar={actualizar}
                      token={token}
                      userid={usuario.id}
                      tipo={tipo}
                      setTipo={setTipo}
                    />
                  </DireccionState>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

function Formulario(props) {
  const {
    handleChange,
    handleSubmit,
    valores,
    errores,
    handleBlur,
    cid,
    actualizar,
    token,
    userid,
    setCid,
    tipo,
    setTipo,
  } = props;
  const {
    tipopersona,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    razonSocial,
    curp,
    rfc,
    telefono,
    celular,
    email,
  } = valores;
  return (
    <Form onSubmit={handleSubmit}>
      <Tab.Container id="tabcontainer" defaultActiveKey="personaBasico">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="personaBasico">Datos Básicos</Nav.Link>
                <Nav.Link eventKey="personaDireccion">Dirección</Nav.Link>
                <Nav.Link eventKey="personaContacto">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="personaBasico">
                <Form.Group>
                  <Form.Label>Tipo Persona</Form.Label>{" "}
                  <Form.Control
                    as="select"
                    name="tipopersona"
                    value={tipopersona}
                    onChange={handleChange}
                  >
                    <option value={0}>Seleccione</option>
                    <option value={parseInt(1)}>Persona física</option>
                    <option value={2}>Persona moral</option>
                  </Form.Control>
                </Form.Group>
                {errores.tipoPersona && (
                  <Danger variante="danger" mensaje={errores.tipoPersona} />
                )}
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Nombre (s)</Form.Label>
                      <Form.Control
                        placeholder="Nombre (s)"
                        name="nombre"
                        value={nombre}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    {errores.nombre && (
                      <Danger variante="danger" mensaje={errores.nombre} />
                    )}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Apellido paterno</Form.Label>
                      <Form.Control
                        placeholder="Apellido paterno"
                        name="apellidoPaterno"
                        value={apellidoPaterno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    {errores.apellidoPaterno && (
                      <Danger
                        variante="danger"
                        mensaje={errores.apellidoPaterno}
                      />
                    )}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Apellido materno</Form.Label>
                      <Form.Control
                        placeholder="Apellido materno"
                        name="apellidoMaterno"
                        value={apellidoMaterno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    {errores.apellidoMaterno && (
                      <Danger
                        variante="danger"
                        mensaje={errores.apellidoMaterno}
                      />
                    )}
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    {tipopersona == 2 ? (
                      <Form.Group>
                        <Form.Label>Razón Social</Form.Label>
                        <Form.Control
                          placeholder="Razón social"
                          name="razonSocial"
                          value={razonSocial}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></Form.Control>
                      </Form.Group>
                    ) : null}
                    {errores.razonSocial && (
                      <Danger variante="danger" mensaje={errores.razonSocial} />
                    )}
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>CURP</Form.Label>
                      <Form.Control
                        placeholder="CURP"
                        value={curp}
                        name="curp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                    {errores.curp && (
                      <Danger variante="danger" mensaje={errores.curp} />
                    )}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>RFC</Form.Label>
                      <Form.Control
                        placeholder="RFC"
                        name="rfc"
                        value={rfc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                    {errores.rfc && (
                      <Danger variante="danger" mensaje={errores.rfc} />
                    )}
                  </Col>
                </Form.Row>
              </Tab.Pane>
              <Tab.Pane eventKey="personaDireccion">
                <Direccion
                  cid={cid}
                  actualizar={actualizar}
                  userid={userid}
                  token={token}
                  setCid={setCid}
                  tipo={tipo}
                  setTipo={setTipo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="personaContacto">
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        placeholder="Teléfono"
                        name="telefono"
                        value={telefono}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Celular</Form.Label>
                      <Form.Control
                        placeholder="Celular"
                        name="celular"
                        value={celular}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        placeholder="Correo Electrónico"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Tab.Pane>
              <Col>
                <Button type="submit">Crear</Button>
              </Col>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Form>
    
  );
}

export default AgregaContribuyente;
