import {
  BUSCAR_CONTRIBUYENTE,
  LISTAR_CONTRIBUYENTE,
  OBTEN_CONTRIBUYENTE,
  REGISTRAR_CONTRIBUYENTE,
  MODIFICAR_CONTRIBUYENTE
} from "../../Types/index";

export default (state, action) => {
  switch (action.type) {
    case REGISTRAR_CONTRIBUYENTE:
      return {
        ...state,
        resultado: action.payload
      };
    case MODIFICAR_CONTRIBUYENTE: 
    {
      return {
      ...state, 
      resultado: action.payload}
    }
    case OBTEN_CONTRIBUYENTE: 
    {
      return {
        ...state,
        contribuyente: action.payload
      }
    }
    case BUSCAR_CONTRIBUYENTE:
    case LISTAR_CONTRIBUYENTE:
      return {
        ...state,
        contribuyentes: action.payload,
      };
    default:
      return state;
  }
};
