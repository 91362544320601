import React from "react";
import ReporteadorReduce from "./ReporteadorReduce";
import localApi from "../../config/Config";
import ReporteadorContext from "./ReporteadorContext";
import {
  GET_GRAFICO1,
  GET_MOTH_LICENCES,
  GET_TOTAL_FACTORY,
  GET_TOTAL_PEOPLE,
  GET_YEAR_LICENCES,
} from "../../Types";
import { useReducer } from "react";

//propiedaeds
const ReporteadorState = (props) => {
  const initialState = {
    mothLicences: [],
    yearLicences: [],
    ttfactory: [],
    ttpeople: [],
    grafico1: [],
  };

  const [state, dispatch] = useReducer(ReporteadorReduce, initialState);

  //Obtener el reporte de licencias del mes.
  const getMothLicences = async (iduser, token) => {
    const result = await localApi.get(`/reporteador/licemitidas/${iduser}`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MOTH_LICENCES,
      payload: result.data.consulta[0],
    });
  };

  //Obtiene el total de licencias del año
  const getYearLicences = async (iduser, token) => {
    const result = await localApi.get(`/reporteador/licanio/${iduser}`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_YEAR_LICENCES,
      payload: result.data.consulta[0],
    });
  };

  //obtiene el total de negocios
  const getTotalFactory = async (iduser, token) => {
    try {
      const result = await localApi.get(`/reporteador/ttnegocios/${iduser}`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: GET_TOTAL_FACTORY,
        payload: result.data.consulta[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  //obtener total de contribuyentes
  const getTotalPeople = async (iduser, token) => {
    try {
      const result = await localApi.get(`/reporteador/ttpeople/${iduser}`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: GET_TOTAL_PEOPLE,
        payload: result.data.consulta[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Obtener el gráfico
  const getGrafico1 = async (iduser,token) => {
    try {
      const result = await localApi.get(`/reporteador/grafico1/${iduser}`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`
        },
      });
      
      dispatch({
        type: GET_GRAFICO1,
        payload: result.data.consulta,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReporteadorContext.Provider
      value={{
        mothLicences: state.mothLicences,
        yearLicences: state.yearLicences,
        ttfactory: state.ttfactory,
        ttpeople: state.ttpeople,
        grafico1: state.grafico1,
        getMothLicences,
        getYearLicences,
        getTotalFactory,
        getTotalPeople,
        getGrafico1
      }}
    >
      {props.children}
    </ReporteadorContext.Provider>
  );
};

export default ReporteadorState;
