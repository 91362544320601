import React from "react";
import { ACCESS_TOKEN } from "../../Constants/Contantes";
import localApi from "../../config/Config";
import { useReducer } from "react";
import authReduce from "./authReduce";
import AuthContext from "./authContext";
import { LOGIN_EXITOSO, OBTENER_USUARIO } from "../../Types";
import jwtDecode from "jwt-decode";
const AuthState = (props) => {
  //Estado inicial
  const initialState = {
    token: localStorage.getItem(ACCESS_TOKEN),
    autenticado: null,
    usuario: null,
  };

  const [state, dispatch] = useReducer(authReduce, initialState);

  //Iniciar sesión
  const IniciarSesion = async (datos) => {
    try {
      const respuesta = await localApi.post("/login/ingresar", datos);
      console.log(respuesta);
      localStorage.setItem(ACCESS_TOKEN, respuesta.data.token);
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
      });
      UsuarioAutenticado();
    } catch (error) {
      console.log(error);
    }
  };
  //Usuario identificado?
  const UsuarioAutenticado = async () => {
    try {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (typeof window === "undefined") {
        return false;
      }

      if (localStorage.getItem("accessToken")) {
        const usuario = jwtDecode(localStorage.getItem(ACCESS_TOKEN));
        const data = {
          token: token,
          usuario: usuario,
        };

        dispatch({
          type: OBTENER_USUARIO,
          payload: data.usuario,
        });
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        IniciarSesion,
        UsuarioAutenticado,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
