//Operaciones del usuario
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const PROYECTO_ERROR = "PROYECTO_ERROR";

//Operaciones de contribuyente
export const REGISTRAR_CONTRIBUYENTE = "REGISTRAR_CONTRIBUYENTE";
export const MODIFICAR_CONTRIBUYENTE = "MODIFICAR_CONTRIBUYENTE";
export const ELIMINAR_CONTRIBUYENTE = "ELIMINAR_CONTRIBUYENTE";
export const BUSCAR_CONTRIBUYENTE = "BUSCAR_CONTRIBUYENTE";
export const LISTAR_CONTRIBUYENTE = "LISTAR_CONTRIBUYENTE";
export const OBTEN_CONTRIBUYENTE = "OBTEN_CONTRIBUYENTE";

//Operaciones de direccion
export const REGISTRA_DIRECCION = "REGISTRA_DIRECCION";
export const OBTENER_LOCALIDADES = "OBTENER_LOCALIDADES";
export const OBTENER_MUNICIPIOS = "OBTENER_MUNICIPIOS";
export const OBTENER_ESTADOS = "OBTENER_ESTADOS";
export const OBTEN_DIRECCION = "OBTEN_DIRECCION";
export const EDITA_DIRECCION = "EDITA_DIRECCION";

//Operaciones con negocios
export const OBTENER_NEGOCIOS = "OBTENER_NEGOCIOS";
export const OBTENER_NEGOCIO = "OBTENER_NEGOCIO";
export const BUSCAR_NEGOCIOS = "BUSCAR_NEGOCIOS";
export const REGISTRAR_NEGOCIO = "REGISTRAR NEGOCIO";
export const EDITAR_NEGOCIO = "EDITAR_NEGOCIO";
export const NEGOCIO_CONTRIBUYENTE = "NEGOCIO_CONTRIBUYENTE";

//Operaciones con licencias de funcionamiento
export const OBTENER_LICENCIAS = "OBTENER_LICENCIAS";
export const CREAR_LICENCIA = "CREAR_LICENCIA";
export const IMPRIMIR_LICENCIA = "IMPRIMIR_LICENCIA";
export const BUSCAR_FOLIO = "BUSCAR_FOLIO";
export const CANCELAR_LICENCIA = "CANCELAR_LICENCIA";
//Access token
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";

//Reporteador
export const GET_MOTH_LICENCES = "GET_MOTH_LICENCES";
export const GET_YEAR_LICENCES = "GET_YEAR_LICENCES";
export const GET_TOTAL_FACTORY = "GET_TOTAL_FACTORY";
export const GET_TOTAL_PEOPLE = "GET_TOTAL_PEOPLE";
export const GET_GRAFICO1 = "GET_FRAFICO1";

//
