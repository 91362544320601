import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layouts from "./layout";
import Routes from "./config/Routes";
// 



import "./css/main.css";
import "./css/animate.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/_components.css";

import 'react-tailwind-table/dist/index.css';

// import "semantic-ui-css/semantic.min.css";
// import "react-toastify/dist/ReactToastify.css";

 




//Apollo Graphql
import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";

import AuthState from "./context/Auth/authState";
import ContribuyenteState from "./context/Contribuyente/contribuyenteState";
import NegociosState from "./context/Negocios/negociosState";
import ReporteadorState from "./context/Reporteador/ReporteadorState";
const Wrapper = ({ children }) => {
  return <Layouts>{children}</Layouts>;
};

function App() {
  return (
    <AuthState>
      <ApolloProvider client={client}>
        <ReporteadorState>
          <NegociosState>
            <ContribuyenteState>
              <Router>
                <Wrapper>
                  <Routes />
                </Wrapper>
              </Router>
            </ContribuyenteState>
          </NegociosState>
        </ReporteadorState>
      </ApolloProvider>
    </AuthState>
  );
}

export default App;
