import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiDelete, FiEdit } from "react-icons/fi";
import NegociosContext from "../../../context/Negocios/negociosContext";
import DireccionContext from "../../../context/Direccion/direccionContext";
import Datatable from "../../DataTable";
import EditarNegocio from "../EditarNegocio/EditarNegocio";

const ListadoNegocios = ({ usuario, token, negocioSearch }) => {
  const contexto = useContext(NegociosContext);
  const dContexto = useContext(DireccionContext);
  const { obtenerNegocios, obenerNegocio, negocios, negocio } = contexto;
  const { obtenDireccion } = dContexto;
  const [actualizar, setActualizar] = useState(false);
  const [abrirModal, setAbrirModal] = useState(false);
  const [cid, setCid] = useState(null);

  const columnas = [
    {
      Header: "Nombre/Razón Social",
      accessor: "razonsocial",
    },
    {
      Header: "RFC",
      accessor: "rfc",
    },
    {
      Header: "Editar",
      accessor: "id",
      Cell: (props) =>
        props.value ? (
          <Opciones id={props.value} openModal={openModal} />
        ) : null,
    },
  ];

  useEffect(() => {
    negocio.length === 0 ? setAbrirModal(false) : setAbrirModal(true);
  }, [negocio]);

  function openModal(id) {
    setCid(id);
    obenerNegocio(usuario.id, token, id);
    obtenDireccion(usuario.id, token, "", id);
  }

  //Obtenemos los negocios
  useEffect(() => {
    obtenerNegocios(usuario.id, token);
  }, []);

  return (
    <>
      <Datatable
        columns={columnas}
        data={negocios}
        negocioSearch={negocioSearch}
        description={"Catálogo de Negocios"}
        titulo={"Negocios activos"}
      />
      {abrirModal === true ? (
        <EditarNegocio
          title={`Editando información de: ${negocio.razonsocial}`}
          open={abrirModal}
          setOpen={setAbrirModal}
          setActualizar={setActualizar}
          actualizar={actualizar}
          uid={usuario.id}
          token={token}
        />
      ) : null}
    </>
  );
};

function Opciones({ id, openModal }) {
  return (
    <>
      <Container>
        <Row>
          <Col md="auto">
            <FiEdit size={14} onClick={() => openModal(id)} />
          </Col>
          <Col md="auto">
            <FiDelete size={14} />
          </Col>
        </Row>
      </Container>
      
    </>
  );
}

export default ListadoNegocios;
