export default function validarContribuyente(valores) {
  let errores = {};
  if (!valores.tipopersona) {
    errores.tipopersona = "El tipo persona es requerido";
  }

  //Si es moral, deberá ser obligatorio
  if (
    (!valores.rfc && valores.tipopersona === 2) ||
    (!valores.rfc && valores.tipopersona === "2")
  ) {
    errores.rfc = "El RFC es requerido para este tipo de persona";
  }
  if (
    (!valores.curp && valores.tipopersona === 1) ||
    (!valores.curp && valores.tipopersona === "1")
  ) {
    errores.curp = "CURP es requerido para este tipo de persona";
  }

  //Si es persona física al menos el nombre y el primer apellido es requerido.
  if (
    (!valores.nombre && valores.tipopersona === 1) ||
    (!valores.nombre && valores.tipopersona === "1")
  ) {
    errores.nombre = "El nombre es requerido";
  }

  if (
    (!valores.apellidoPaterno && valores.tipopersona === 1) ||
    (!valores.apellidoPaterno && valores.tipopersona === "1")
  ) {
    errores.apellidoPaterno = "El primer apellido es requerido";
  }

  if (
    (!valores.razonSocial && valores.tipopersona === 2) ||
    (!valores.razonSocial && valores.tipopersona === "2")
  ) {
    errores.razonSocial = "La razón social es requerida para este tipo persona";
  }
  if (
    (!rfcValido(valores.rfc) && valores.tipopersona === 2) ||
    (!rfcValido(valores.rfc) && valores.tipopersona === "2")
  ) {
    errores.rfc = "Ingresa un RFC valido";
  }
  //alert(valores.curp);

  ///Validamos la curp
  if (
    (!curpValida(valores.curp) && valores.tipopersona === 1) ||
    (!curpValida(valores.curp) && valores.tipopersona === "1")
  ) {
    errores.curp = "Ingresa una CURP valida";
  }

  return errores;
}

//Función para validar un RFC
// Devuelve el RFC sin espacios ni guiones si es correcto
// Devuelve false si es inválido
// (debe estar en mayúsculas, guiones y espacios intermedios opcionales)
function rfcValido(rfc, aceptarGenerico = true) {
  const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var validado = rfc.match(re);

  if (!validado)
    //Coincide con el formato general del regex?
    return false;

  //Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
    rfcSinDigito = validado.slice(1).join(""),
    len = rfcSinDigito.length,
    //Obtener el digito esperado
    diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    indice = len + 1;
  var suma, digitoEsperado;

  if (len === 12) suma = 0;
  else suma = 481; //Ajuste para persona moral

  for (var i = 0; i < len; i++)
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - (suma % 11);
  if (digitoEsperado === 11) digitoEsperado = 0;
  else if (digitoEsperado == 10) digitoEsperado = "A";

  //El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if (
    digitoVerificador != digitoEsperado &&
    (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")
  )
    return false;
  else if (
    !aceptarGenerico &&
    rfcSinDigito + digitoVerificador == "XEXX010101000"
  )
    return false;
  return rfcSinDigito + digitoVerificador;
}

//Función para validar una CURP
function curpValida(curp) {
  var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    validado = curp.match(re);

  if (!validado)
    //Coincide con el formato general?
    return false;

  //Validar que coincida el dígito verificador
  function digitoVerificador(curp17) {
    //Fuente https://consultas.curp.gob.mx/CurpSP/
    var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
      lngSuma = 0.0,
      lngDigito = 0.0;
    for (var i = 0; i < 17; i++)
      lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigito = 10 - (lngSuma % 10);
    if (lngDigito == 10) return 0;
    return lngDigito;
  }

  if (validado[2] != digitoVerificador(validado[1])) return false;

  return true; //Validado
}
