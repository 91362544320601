import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { FiSettings, FiMenu } from "react-icons/fi";
import Dropdown1 from "./dropdown-1";

import Search from "./search";
import "../../css/components/navbar.css";

const Navbar = () => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { rightSidebar, collapsed } = { ...config };
  const dispatch = useDispatch();
  return (
    <div className="navbar navbar-1 border-b">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: "SET_CONFIG_KEY",
              key: "collapsed",
              value: !collapsed,
            })
          }
          className="mx-4"
        >
          <FiMenu size={20} />
        </button>
        <Search />
        {/* //Aquí va la parte de las apps */}
        <span className="ml-auto"></span>

        <Dropdown1 />
      </div>
    </div>
  );
};

export default Navbar;
