/**
 * Componente: Listado de licencias de funcionaminto
 * Fecha de programación 12 de nov de 2019
 * Fecha de actualización: 13 de octubre de 2021
 * Francisco Javier Guerrero Martínez
 */
import React from "react";
import Table from "react-tailwind-table";
import { FiPrinter } from "react-icons/fi";
import printJS from "print-js";
//Graphql [Actualización 2.0]
import { useQuery, useMutation } from "@apollo/client";
import {
  OBTENER_LICENCIAS,
  IMPRIMIR_LICENCIA,
} from "../../../graphql/Licencias";

const ListadoLicencia = () => {
  //Actualización 13 de octubre de 2021
  const { data, loading, error } = useQuery(OBTENER_LICENCIAS);
  const [imprimirLicencia] = useMutation(IMPRIMIR_LICENCIA);

  const columnas = [
    {
      field: "folio",
      use: "Folio de Licencia",
      use_in_search: true,
    },
    {
      field: "contribuyente",
      use: "Contribuyente",
      use_in_search: true,
    },
    {
      field: "id",
      use: "Acciones",
    },
  ];

  const rowcheck = (row, column) => {
    if (column.field === "id") {
      return (
        <div className="mb-2">
          <FiPrinter
            onClick={async () => {
              //setId(row.id);
              const { data } = await imprimirLicencia({
                variables: { id: row.id },
              });
              imprime(data.imprimirLicencia);
            }}
          />
        </div>
      );
    }
  };

  //Imprime licencia
  const imprime = (id) => {
    printJS({
      printable: id,
      type: "pdf",
      showModal: true,
      onError: function (error) {
        alert("Error found => " + error.message);
      },
    });
  };

  //Validamos si está cargando
  if (loading) return <h1>Cargando...</h1>;
  if (error)
    return <h1>Error al procesar la solicitud, contacte con sistemas.</h1>;
  const { obtenerLicencias } = data;

  return (
    <>
      <Table
        columns={columnas}
        rows={obtenerLicencias}
        table_header="Licencias de funcionamiento expedidas"
        row_render={rowcheck}
      />
    </>
  );
};

export default ListadoLicencia;
