import React, { useState } from "react";
import Title from "../../../components/Escritorio/TItle";
import BusquedaPredial from "../../../components/Tools/Predial/BusquedaPredial";
import ListadoBusqueda from "../../../components/Tools/Predial/ListadoBusqueda";
const Catastro = () => {
  const [resultados, setResultados] = useState([]);

  return (
    <>
      <Title
        titulo="Consultar adeudo predial"
        subtitulo="Te permite consultar si un contribuyente tiene adeudos"
      />
      <BusquedaPredial setResultados={setResultados} />
      <ListadoBusqueda resultados={resultados} />
    </>
  );
};

export default Catastro;
