import React, { useContext, useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import NegociosContext from "../../../context/Negocios/negociosContext";
import AuthContext from "../../../context/Auth/authContext";
const Buscarnegocios = () => {
  const [data, setBusqueda] = useState({
    busqueda: "",
    tipo: 0,
  });
  const nContexto = useContext(NegociosContext);
  const authContext = useContext(AuthContext);
  const { buscarNegocio } = nContexto;
  const { usuario, token } = authContext;
  const Buscar = (e) => {
    e.preventDefault();
    buscarNegocio(usuario.id, token, data);
  };
  return (
    
    <Form onSubmit={Buscar}>
      <Row>
        <Col lg={6}>
          <Form.Group as={Row}>
            <Col xs={12}>
              <Form.Control
                placeholder="Ingrese clave de busqueda"
                className="form-input "
                value={data.busqueda}
                name="busqueda"
                onChange={(e) =>
                  setBusqueda({ ...data, busqueda: e.target.value })
                }
              />
            </Col>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group as={Row}>
            <Col xs={10}>
              <Form.Control
                as="select"
                className="form-input"
                name="tipo"
                value={data.tipo}
                onChange={(e) => {
                  setBusqueda({ ...data, tipo: e.target.value });
                }}
              >
                <option value="1">Razón Social</option>
                <option value="2">RFC</option>
              </Form.Control>
            </Col>
            <Col xs={2}>
              <Button type="submit">Buscar</Button>
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Buscarnegocios;
