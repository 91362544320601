import React from "react";
import LoginForm from "../components/Login";
import Layout from "../layout/centro/centro";
import FrmCenter from "../layout/form-centro/";
const Login = (props) => {
  return (
    <Layout>
      <FrmCenter
        title="Ingresar"
        subtitle="Ingresa tu usuario y contraseña para iniciar sesión."
      >
        <LoginForm props={props}/>
      </FrmCenter>
    </Layout>
    
  );
};

export default Login;
