import React, { useReducer } from "react";
import LicenciasReduce from "./LicenciasReduce";
import LicenciasContext from "./LicenciasContext";
import localApi from "../../config/Config";
import printJS from "print-js";
import {
  OBTENER_LICENCIAS,
  MSG_LICENCIA,
  CREAR_LICENCIA,
  IMPRIMIR_LICENCIA,
  BUSCAR_FOLIO,
  CANCELAR_LICENCIA,
} from "../../Types";
const LicenciasState = (props) => {
  const initialState = {
    licencias: [],
    licencia: {},
    informacion: {},
    urlprint: "",
  };

  const [state, dispatch] = useReducer(LicenciasReduce, initialState);

  //Obtener licencias de funcionamiento.
  const obtenerLicencias = async (id, token) => {
    try {
      const result = await localApi.get(`/licencias/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: OBTENER_LICENCIAS,
        payload: result.data.consulta,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Creamos la licencia de funcionamiento.
  const crearLicencia = async (id, token, datos) => {
    // try {
    await localApi
      .post(`/licencias/${id}`, datos, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch({
          type: CREAR_LICENCIA,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREAR_LICENCIA,
          payload: error.response.data,
        });
      });
  };

  //Ruta para imprimir la licencia de funcionamiento
  const imprimirLicencia = async (id, token, idlicencia) => {
    await localApi
      .get(`/licencia/print/${id}?idlicencia=${idlicencia}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((result) => {
       
        dispatch({
          type: IMPRIMIR_LICENCIA,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: IMPRIMIR_LICENCIA,
          payload: error.response.data,
        });
      });
  };

  //Buscar folio paa cancelar
  const buscarFolio = async (id, token, folio) => {
  
    await localApi
      .get(`/licencia/buscar/${id}?folio=${folio}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((result) => {
        dispatch({
          type: BUSCAR_FOLIO,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: BUSCAR_FOLIO,
          payload: error.response.data,
        });
      });
  };

  //cancelar la licencia
  const cancelarLicencia = async (id, token, data) => {
    await localApi
      .post(
        `/licencia/cancelar/${id}`,
         data ,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        dispatch({
          type: CANCELAR_LICENCIA,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CANCELAR_LICENCIA,
          payload: error.response.data,
        });
      });
  };

  return (
    <LicenciasContext.Provider
      value={{
        licencias: state.licencias,
        licencia: state.licencia,
        informacion: state.informacion,
        urlprint: state.urlprint,
        obtenerLicencias,
        crearLicencia,
        imprimirLicencia,
        buscarFolio,
        cancelarLicencia
      }}
    >
      {props.children}
    </LicenciasContext.Provider>
  );
};

export default LicenciasState;
