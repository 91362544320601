import { gql } from "@apollo/client";

//REALIZAR LA BUSQUEDA
export const PREDIO_PAGADO_QUERY = gql`
  mutation PREDIO_PAGADO($tipoBusqueda: String, $cadena: String) {
    predioPagado(tipoBusqueda: $tipoBusqueda, cadena: $cadena) {
      nombre
      cuenta_predial
      pago
    }
  }
`;
