import { gql } from "@apollo/client";

//OBTENER LICENCIAS DE FUNCIONAMIENTO
export const OBTENER_LICENCIAS = gql`
  query OBTENER_LICENCIAS {
    obtenerLicencias {
      id
      folio
      razonSocial
      contribuyente
    }
  }
`;

//VOLVER A GENERAR LA LICENCIA DE FUNCIONAMIENTO
export const IMPRIMIR_LICENCIA = gql`
  mutation IMPRIMIR_LICENCIA($id: ID) {
    imprimirLicencia(id: $id)
  }
`;
