import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth/authContext";
import { useFormik } from "formik";
import * as Yup from "yup";
const LoginForm = ({ props }) => {
  const authContext = useContext(AuthContext);
  const { IniciarSesion, autenticado } = authContext;

  //Effecto para comprobar si el usuario está authenticado
  useEffect(() => {
    if (autenticado) {
      props.history.push("/home");
    }
  }, [autenticado, props.history]);

  //Formik implementation
  const formik = useFormik({
    initialValues: {
      usuarioid: "",
      password: "",
    },
    validationSchema: Yup.object({
      usuarioid: Yup.string().required("El usuario es requierido"),
      password: Yup.string().required("La contraseña es requerida"),
    }),
    onSubmit: async (data) => {
      IniciarSesion({ usuarioid: data.usuarioid, password: data.password });
    },
  });
  return (
    <>
      <div className="flex flex-col">
        <form
          className="form flex flex-wrap w-full"
          onSubmit={formik.handleSubmit}
        >
          <div className="w-full">
            <div className="form-element">
              <div className="form-label">Usuario</div>
              <input
                type="text"
                className="form-input"
                id="usuarioid"
                placeholder="Usuario"
                value={formik.values.usuarioid}
                onChange={formik.handleChange}
              />
              <p className="text-danger">{formik.errors.usuarioid}</p>
            </div>
            <div className="form-element">
              <div className="form-label">Contraseña</div>
              <input
                type="password"
                className="form-input"
                name="password"
                placeholder="Contraseña"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <p className="text-danger">{formik.errors.password}</p>
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
            value="Ingresar"
          />
        </form>
      </div>
    </>
  );
};

export default LoginForm;
