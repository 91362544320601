import {
  OBTENER_NEGOCIOS,
  BUSCAR_NEGOCIOS,
  REGISTRAR_NEGOCIO,
  OBTENER_NEGOCIO,
  EDITAR_NEGOCIO,
  NEGOCIO_CONTRIBUYENTE,
} from "../../Types";

export default (state, action) => {
  switch (action.type) {
    case REGISTRAR_NEGOCIO:
      return {
        ...state,
        negocio: action.payload,
      };
    case OBTENER_NEGOCIO:
      return {
        ...state,
        negocio: action.payload,
      };
    case NEGOCIO_CONTRIBUYENTE:
      return{
        ...state,
        negocios: action.payload
      }
    case EDITAR_NEGOCIO: {
      return {
        ...state,
        negocio: action.payload,
      };
    }
    case BUSCAR_NEGOCIOS:
    case OBTENER_NEGOCIOS: {
      return {
        ...state,
        negocios: action.payload,
      };
    }
    default:
      return state;
  }
};
